import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import ApiService from "../plugins/axios";
import encodeUrl from "../plugins/encode";
import setCssVars from "../plugins/css";
import { component_settings_handler } from "../plugins/project-settings";
import Loading from 'quasar/src/plugins/Loading.js';;

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;
let CURRENT_ROUTE = {};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/energyportal",
    name: "EnergyPortal",
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store.dispatch("setError", { message: "", show: false });
      store.dispatch("setUrl", window.location.origin + to.fullPath);

      to.query.bac = true;
      store.dispatch("openFlow", to.query).then(() => {
        if (to.query?.viewmode && to.query?.viewmode == "user") {
          store.dispatch("setViewMode", true);
          next();
          return;
        }
        store.dispatch("setProjectSpecificValue", {
          key: "url_org",
          value: store.getters.url,
        });
      });
      store
        .dispatch("getProjectSettings", {
          token: to.query.Token,
        })
        .then(() => {
          store.dispatch(
            "setWriteOffSettings",
            store.getters.projectSettings.writeOff_settings
          );
          next();
        });
    },
    component: () => import("../views/EnergyPortal.vue"),
  },
  {
    path: "/retention",
    name: "Retention",
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store.dispatch("setError", { message: "", show: false });
      store.dispatch("setUrl", window.location.origin + to.fullPath);
      store.dispatch("openFlow", to.query).then(() => {
        if (to.query?.viewmode && to.query?.viewmode == "user") {
          store.dispatch("setViewMode", true);
          next();
          return;
        }
        store.dispatch("setProjectSpecificValue", {
          key: "url_org",
          value: store.getters.url,
        });
      });
      store
        .dispatch("getProjectSettings", {
          token: to.query.Token,
        })
        .then(() => {
          store.dispatch(
            "setWriteOffSettings",
            store.getters.projectSettings.writeOff_settings
          );
          next();
        });
    },
    component: () => import("../views/Retention.vue"),
  },
  {
    path: "/multioffer",
    name: "MultiOffer",
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      Loading.show({
        message: "Project instellingen ophalen...",
      });
      store.dispatch("setError", { message: "", show: false });
      store.dispatch("setUrl", window.location.origin + to.fullPath);
      store.dispatch("openFlow", to.query).then(() => {
        if (to.query?.viewmode && to.query?.viewmode == "user") {
          store.dispatch("setViewMode", true);
          return;
        }
        store.dispatch("setProjectSpecificValue", {
          key: "url_org",
          value: store.getters.url,
        });
      });
      store
        .dispatch("getProjectSettings", {
          token: to.query.Token,
        })
        .then(() => {
          setCssVars(store.getters.projectSettings);
          store.dispatch(
            "setWriteOffSettings",
            store.getters.projectSettings.writeOff_settings
          );
          component_settings_handler(
            JSON.parse(store.getters.projectSettings.component_settings)
          ).then((response) => {
            Loading.show({
              message: "Project instellingen toepassen...",
            });
            store.dispatch("setComponentSettings", response);
            Loading.hide();
            next();
          });
        });
      // next();
    },
    component: () => import("../views/MultiOffer.vue"),
  },
  {
    path: "/landing",
    name: "Landing",
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store.dispatch("getLandingContent", {
        record_id: to.query.recordid,
        project_id: to.query.projectid,
        iv: to.query.iv,
      });
      store
        .dispatch("getProjectSettings", {
          id: to.query.projectid,
          iv: to.query.iv,
        })
        .then(() => {
          setCssVars(store.getters.projectSettings);
          next();
        });
    },
    component: () => import("../views/Landing.vue"),
  },
  {
    path: "/data_table",
    name: "DataTable",
    // eslint-disable-next-line no-unused-vars
    beforeEnter: (to, from, next) => {
      store.dispatch("setError", { message: "", show: false });
      next();
    },
    component: () => import("../views/DataTable.vue"),
    props: { column_key: "project_settings" },
  },
];

const router = createRouter({
  history: createWebHistory("/vue/"),
  routes,
});

router.beforeEach((to) => {
  store.commit("setView", to.name.toLowerCase());
  CURRENT_ROUTE = to;
});

ApiService.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    store.dispatch("setPendingRequests", PENDING_REQUESTS);
    return Promise.resolve(response);
  },
  //* Intercept all API calls and redirect to login page when session is not valid
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    store.dispatch("setPendingRequests", PENDING_REQUESTS);
    if (error.response.status === 401) {
      const nextUrl = encodeUrl(CURRENT_ROUTE);
      location.href = nextUrl;
    }

    if (error.response.status === 403) {
      console.log(error.response);
      store.dispatch("setError", { message: error.response.data, show: true });
    }
    return Promise.reject(error);
  }
);

ApiService.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        store.dispatch("setPendingRequests", PENDING_REQUESTS);
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

export default router;
