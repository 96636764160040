import setCssVar from 'quasar/src/utils/set-css-var.js';import Loading from 'quasar/src/plugins/Loading.js';;
const setCssVars = (settings) => {
  if (!settings.branding || settings.branding.length <= 0) {
    return;
  }

  const branding = JSON.parse(settings.branding);

  for (const [key, value] of Object.entries(branding)) {
    setCssVar(key, value);
  }

  if (Object.prototype.hasOwnProperty.call(branding, "loadingDefaults")) {
    setLoadingDefaults(branding);
  }
};

const setLoadingDefaults = (branding) => {
  const loadingDefaults = {
    spinnerColor: "primary",
    messageColor: "black",
    backgroundColor: "white",
  };

  for (const [key, value] of Object.entries(loadingDefaults)) {
    if (Object.prototype.hasOwnProperty.call(branding.loadingDefaults, key)) {
      loadingDefaults[key] = value;
    }
  }

  Loading.setDefaults(loadingDefaults);
};

export default setCssVars;
