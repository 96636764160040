import axios from "axios";
// import store from "../store/index";

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //   Authorization: `Bearer ${store.getters.token}` || "",
  },
});

export default ApiService;
