import store from "../../store/index";
import Loading from 'quasar/src/plugins/Loading.js';;

const get_brand = () => {
  let brand = store.getters.projectSpecific["Energieleverancier"] ?? "essent";

  if (
    store.getters.projectSpecific["Energieleverancier"] &&
    store.getters.projectSpecific["Energieleverancier"] == "ED"
  ) {
    brand = "energiedirect";
  }

  if (store.getters.view === "multioffer") {
    brand = "multioffer";
  }

  return brand.toLowerCase();
};

const get_offers = (selected_product = null) => {
  if (!Loading.isActive && !store.getters.viewMode) {
    Loading.show();
  }
  if (selected_product === null) {
    selected_product = store.getters.projectSpecific["selectedProduct"];
  }
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    task: "get_offers",
    state: false,
    route: {
      branch: selected_product.product.segment,
      brand: selected_product.product.brand ?? get_brand(),
      product: selected_product.product.type,
    },
    body: {
      Huisnummer: store.getters.record.Huisnummer,
      HuisnummerToev: store.getters.record.HuisnummerToev,
      Postcode: store.getters.record.Postcode,
      Achternaam: store.getters.record.Achternaam,
      Segment: store.getters.projectSpecific["Segment"],
      SwitchOrMove: store.getters.projectSpecific["SwitchOrMove"],
      campaignid: store.getters.record.External_campagne,
      incentive: selected_product?.incentive?.IncentiveId ?? "",
      incentiveName: selected_product.incentive.incentiveName ?? "Geen",
      incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
      product_titel: selected_product.product.selectielijst,
      start_date: store.getters.projectSpecific["StartDate"],
      type_id: selected_product.type_id,
      product_id: selected_product.product.productid,
      duur: selected_product.product.duur,
      verbruikelectra: store.getters.projectSpecific["verbruik electra"] ?? "",
      verbruikelectra2:
        store.getters.projectSpecific["verbruik electra 2"] ?? "",
      verbruikelectrateruglevering:
        store.getters.projectSpecific["verbruik electra teruglevering"] ?? "",
      verbruikgas: store.getters.projectSpecific["verbruik gas"] ?? "",
      verblijfsFunctie: store.getters.projectSpecific["verblijfsFunctie"],
      Projecttype: store.getters.projectSpecific["Projecttype"],
      Userid: store.getters.record.Userid,
      record_PID: store.getters.record.id,
      MeterType: store.getters.projectSpecific["type meter"],
      Project: store.getters.steamVelden["project"],
      projectSpecificParts: {
        product: selected_product.product.productid,
        incentive: selected_product?.incentive?.IncentiveId ?? "",
        incentiveName: selected_product.incentive.incentiveName ?? "Geen",
        incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
        duur: selected_product.product.duur,
        Energieleverancier:
          store.getters.projectSpecific["Energieleverancier"] ?? "Essent",
      },
      steamParts: {
        campagne: store.getters.steamVelden["campagne"],
        campaignid: selected_product.product.campaignid,
        project: store.getters.steamVelden["project"],
      },
    },
  });
};

const get_all_offers = () => {
  const selected_products = store.getters.selectedProducts;
  selected_products.forEach((selected_product) => {
    if ((selected_product.product.length ?? []) === 0) return;

    get_offers(selected_product);
  });
};

const get_city = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { route: { brand: get_brand() } },
    task: "get_city",
    state: false,
    body: {
      record_PID: store.getters.record.id,
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      house_number_extension: store.getters.record.HuisnummerToev ?? "",
    },
  });
};

export { get_offers, get_all_offers, get_city };
