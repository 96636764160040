import { defineAsyncComponent } from "vue";

// Dynamically import components based on component settings
const component_settings_handler = (component_settings) => {
  const components_map = {
    components: {},
    stepper: {},
    child_component_settings: {},
    settings: {},
  };

  return new Promise((resolve) => {
    // eslint-disable-next-line no-unused-vars
    for (const [key, setting] of Object.entries(component_settings)) {
      components_map.components[setting.name] = defineAsyncComponent(() =>
        import("../components" + setting.prefix + "/" + setting.name + ".vue")
      );
      components_map.stepper[setting.name] = setting.stepper ?? null;
      if (components_map.stepper[setting.name] == null) {
        delete components_map.stepper[setting.name];
      }

      components_map.child_component_settings[setting.name] =
        setting.child_component_settings ?? {};
      components_map.settings[setting.name] = setting.settings ?? {};
    }

    resolve(components_map);
  });
};
export { component_settings_handler };
