import store from "../../store/index";
import Loading from 'quasar/src/plugins/Loading.js';import date from 'quasar/src/utils/date.js';;

const { formatDate } = date;

const get_brand = () => {
  let brand = store.getters.projectSpecific["Energieleverancier"] ?? "essent";

  if (
    store.getters.projectSpecific["Energieleverancier"] &&
    store.getters.projectSpecific["Energieleverancier"] == "ED"
  ) {
    brand = "energiedirect";
  }

  if (store.getters.view === "multioffer") {
    brand = "multioffer";
  }

  return brand.toLowerCase();
};

const start_sac_flow = () => {
  const now = Date.now();

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "start_sac_flow",
    state: false,
    body: {
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      house_number_extension: store.getters.record.HuisnummerToev ?? "",
      contact_date: formatDate(
        store.getters?.Email_verzonden ?? now,
        "YYYY-MM-DD"
      ),
      account_id: store.getters.projectSpecific.EssentKlantnummer,
      record_PID: store.getters.record.id,
    },
  });
};

const get_current_consumption = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "get_current_consumption",
    state: true,
    body: {
      record_id: store.getters.record.id,
      record_PID: store.getters.record.id,
    },
  });
};

const save_consumption = () => {
  const body = {
    record_PID: store.getters.record.id,
    payload: [
      {
        energy_type: "electricity",
        standard_annual_usages: [
          {
            direction_tariff: "supply_normal",
            reading:
              parseFloat(store.getters.projectSpecific["verbruik electra"]) ||
              0,
          },
        ],
      },
    ],
  };
  if (
    store.getters.projectSpecific["verbruik electra 2"] ||
    store.getters.projectSpecific["verbruik electra 2"] === 0
  ) {
    body.payload[0].standard_annual_usages.push({
      direction_tariff: "supply_low",
      reading: parseFloat(store.getters.projectSpecific["verbruik electra 2"]),
    });
  }
  if (
    store.getters.projectSpecific["verbruik electra teruglevering"] ||
    store.getters.projectSpecific["verbruik electra teruglevering"] === 0
  ) {
    body.payload[0].standard_annual_usages.push({
      direction_tariff: "return_supply_normal",
      reading: parseFloat(
        store.getters.projectSpecific["verbruik electra teruglevering"]
      ),
    });
  }

  if (
    store.getters.projectSpecific["verbruik gas"] ||
    store.getters.projectSpecific["verbruik gas"] === 0
  ) {
    body.payload.push({
      energy_type: "gas",
      standard_annual_usages: [
        {
          direction_tariff: "supply_normal",
          reading: parseFloat(store.getters.projectSpecific["verbruik gas"]),
        },
      ],
    });
  }

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_consumption",
    state: true,
    body,
  });
};

const get_offers = () => {
  if (!Loading.isActive && !store.getters.viewMode) {
    Loading.show();
  }
  const duration =
    store.getters.projectSpecific["selectedProduct"].product.duur.split(" ");
  const selected_product = store.getters.projectSpecific["selectedProduct"];

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    task: "get_offers",
    state: false,
    route: {
      branch: selected_product.product.segment ?? "b2c",
      brand: selected_product.product.brand ?? get_brand(),
      product: selected_product.product.type ?? "energy",
    },
    body: {
      Huisnummer: store.getters.record.Huisnummer,
      HuisnummerToev: store.getters.record.HuisnummerToev,
      Postcode: store.getters.record.Postcode,
      Achternaam: store.getters.record.Achternaam,
      Segment: store.getters.projectSpecific["Segment"],
      SwitchOrMove: store.getters.projectSpecific["SwitchOrMove"],
      campaignid: store.getters.record.External_campagne,
      incentive: selected_product.incentive.IncentiveId,
      incentiveName: selected_product.incentive.incentiveName ?? "Geen",
      incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
      product_titel: selected_product.product.selectielijst,
      start_date: store.getters.projectSpecific["StartDate"],
      type_id: selected_product.type_id,
      product_id: selected_product.product.productid,
      duur: selected_product.product.duur,
      verbruikelectra: store.getters.projectSpecific["verbruik electra"] ?? "",
      verbruikelectra2:
        store.getters.projectSpecific["verbruik electra 2"] ?? "",
      verbruikelectrateruglevering:
        store.getters.projectSpecific["verbruik electra teruglevering"] ?? "",
      verbruikgas: store.getters.projectSpecific["verbruik gas"] ?? "",
      verblijfsFunctie: store.getters.projectSpecific["verblijfsFunctie"],
      Projecttype: store.getters.projectSpecific["Projecttype"],
      Userid: store.getters.record.Userid,
      record_PID: store.getters.record.id,
      MeterType: store.getters.projectSpecific["type meter"],
      Project: store.getters.steamVelden["project"],
      projectSpecificParts: {
        product: selected_product.product.productid,
        incentive: selected_product.incentive.IncentiveId,
        incentiveName: selected_product.incentive.incentiveName ?? "Geen",
        incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
        duur: selected_product.product.duur,
        Energieleverancier:
          store.getters.projectSpecific["Energieleverancier"] ?? "Essent",
      },
      steamParts: {
        campagne: store.getters.steamVelden["campagne"],
        campaignid: selected_product.product.campaignid,
        project: store.getters.steamVelden["project"],
      },
      campaign_id:
        store.getters.projectSpecific["selectedProduct"].product.campaignid,
      incentive_id:
        store.getters.projectSpecific["selectedProduct"].incentive
          .IncentiveId == "Geen"
          ? ""
          : store.getters.projectSpecific["selectedProduct"].incentive
              .IncentiveId,
      duration_in_years: duration[0],
    },
  });
};

const change_start_date_and_select_proposition = () => {
  let startDate = store.getters.projectSpecific["StartDate"];
  const tmp = startDate.split("-");
  const newDate = new Date(tmp[2], tmp[1] - 1, tmp[0]);

  const duration =
    store.getters.projectSpecific["selectedProduct"].product.duur.split(" ");

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "change_start_date_and_select_proposition",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      start_date: formatDate(newDate, "YYYY-MM-DD"),
      skip_cooldown: true,
      campaign_id:
        store.getters.projectSpecific["selectedProduct"].product.campaignid,
      incentive_id:
        store.getters.projectSpecific["selectedProduct"].incentive
          .IncentiveId == "Geen"
          ? store.getters.projectSpecific["selectedProduct"].product
              .incentiveidno
          : store.getters.projectSpecific["selectedProduct"].incentive
              .IncentiveId,
      duration_in_years: duration[0],
    },
  });
};

const select_proposition = () => {
  const duration =
    store.getters.projectSpecific["selectedProduct"].product.duur.split(" ");

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "select_proposition",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      campaign_id:
        store.getters.projectSpecific["selectedProduct"].product.campaignid,
      incentive_id:
        store.getters.projectSpecific["selectedProduct"].incentive
          .IncentiveId == "Geen"
          ? ""
          : store.getters.projectSpecific["selectedProduct"].incentive
              .IncentiveId,
      duration_in_years: duration[0],
    },
  });
};

export {
  start_sac_flow,
  get_current_consumption,
  save_consumption,
  get_offers,
  change_start_date_and_select_proposition,
  select_proposition,
};
