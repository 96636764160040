<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <error />
      <router-view v-show="!error"></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Error from "./components/Error.vue";

export default {
  name: "LayoutDefault",

  components: {
    Error,
  },

  setup() {
    const $store = useStore();

    return {
      leftDrawerOpen: ref(false),
      step: ref(1),
      error: computed(() => {
        return $store.getters.error.show;
      }),
    };
  },
};
</script>
