import { ref } from "vue";

let CC_IntegrationScript = document.createElement("script");
CC_IntegrationScript.setAttribute(
  "src",
  process.env.VUE_APP_CC_INTEGRATION_LOCATION
);
document.head.appendChild(CC_IntegrationScript);
// let CC_checkBrowser = window.CC_checkBrowser;
// let CC_enabled = window.CC_enabled;
// let CC_VlogName = window.CC_VlogName;
// let CC_chRecording = window.chRecording;
// let CC_bridge = window.CC_bridge;
const cc_Enabled = ref(false);
const cc_IsEnabled = () => {
  cc_Enabled.value = window.CC_IsEnabled();
  return cc_Enabled;
};
const cc_GotoPage = (page) => {
  return window.CC_gotoPage(page);
};
const cc_RecordingStatus = () => {
  return window.CC_RecordingStatus();
};
const cc_RecordingStart = (vlogName, callback, options, exception) => {
  return window.CC_RecordingStart(vlogName, callback, options, exception);
};
const cc_RecordingStop = (callback, options) => {
  return window.CC_RecordingStop(callback, options);
};
const cc_RecordingName = () => {
  return window.CC_recordingName();
};

export {
  cc_Enabled,
  cc_IsEnabled,
  cc_GotoPage,
  cc_RecordingStatus,
  cc_RecordingStart,
  cc_RecordingStop,
  cc_RecordingName,
};
