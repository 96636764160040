import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    animations: "all",
  },
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
};
