<template>
  <q-dialog v-model="error.show" persistent>
    <q-card class="dialog">
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ error.message }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          label="Terug naar Orderportal"
          color="primary"
          @click="error = 'error'"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Error",

  setup() {
    const $store = useStore();

    return {
      error: computed({
        get() {
          return $store.getters.error;
        },
        set() {
          // $store.dispatch("setError", { message: "", show: false });
          location.href = process.env.VUE_APP_BASE_ENCODE_URL;
        },
      }),
    };
  },
};
</script>
<style lang="sass">
@import '../styles/quasar.sass'
.dialog
    border: 2px solid $accent-salsa
</style>
