import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_WS_BASE_URL,
  wsPort: 6001,
  wssPort: 443,
  wsPath: process.env.VUE_APP_WS_PATH,
  forceTLS: false,
  disableStats: true,
  encrypted: true,
  enabledTransports: ["ws", "wss"],
});

export { echo };
