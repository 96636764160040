import ApiService from "../../plugins/axios";

const datatable = {
  namespaced: true,

  state: () => ({
    currentRow: {},
    rows: {},
    componentMapping: [],
    api: {
      list: null,
      update: null,
      delete: null,
      create: null,
    },
  }),
  mutations: {
    setCurrentRow: (state, currentRow) => (state.currentRow = currentRow),
    setRows: (state, rows) => (state.rows = rows),
    setApiDefinitions: (state, apiDefinitions) => (state.api = apiDefinitions),
    setComponentMapping: (state, setComponentMapping) =>
      (state.componentMapping = setComponentMapping),
  },
  actions: {
    getRows: ({ getters, commit }) => {
      ApiService.post(getters.apiDefinitions?.list).then((response) => {
        commit("setRows", response.data);
      });
    },
    setCurrentRow: ({ commit }, currentRow) => {
      commit("setCurrentRow", currentRow);
    },
    setComponentMapping: ({ commit }, setComponentMapping) => {
      commit("setComponentMapping", setComponentMapping);
    },
    updateRow: ({ getters, dispatch }, row) => {
      ApiService.post(getters.apiDefinitions?.update, row).then(() => {
        dispatch("getRows");
      });
    },
    deleteRow: ({ getters, dispatch }, id) => {
      ApiService.post(getters.apiDefinitions?.delete, { id }).then(() => {
        dispatch("getRows");
      });
    },
    setApiDefinitions: ({ commit }, apiDefinitions) => {
      commit("setApiDefinitions", apiDefinitions);
    },
  },
  getters: {
    currentRow: (state) => state.currentRow,
    rows: (state) => state.rows,
    apiDefinitions: (state) => state.api,
    componentMapping: (state) => state.componentMapping,
  },
};

export { datatable };
