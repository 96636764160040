import store from "../store/index";

import * as bac_message from "./bac/message";
import * as sac_message from "./sac/message";
import * as mo_message from "./multioffer/message";

const route_map = {
  energyportal: bac_message,
  retention: sac_message,
  multioffer: mo_message,
};

const route_message = ({ view, message }) => {
  if (!route_map[view] || !route_map[view][message]) {
    return null;
  }

  const route = route_map[view];
  return route[message];
};

const send_message = ({ message, body = null }) => {
  const send = route_message({ view: store.getters.view, message });
  if (!send) {
    return null;
  }

  send(body);
};

export { send_message };
