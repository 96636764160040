import store from "../store/index";

const map_products = (products) => {
  const mappedProducts = {};
  const listProducts = [];
  const options = [];
  for (const [duration, duration_products] of Object.entries(products)) {
    mappedProducts[duration] = mappedProducts[duration] ?? {};

    if (!options.find((option) => option.value == duration)) {
      options.push({
        value: duration,
        label: duration,
      });
    }

    for (const [product_id, product] of Object.entries(duration_products)) {
      mappedProducts[duration][product_id] = product;

      product.key = product_id;
      listProducts.push(product);
    }
  }

  store.dispatch("setProducts", {
    products: mappedProducts,
    options: options.sort(sort_options),
    list_products_array: listProducts.sort(sort_list),
  });
};

/**
 * Sorts Options based on first digit within duration.
 * Whenever duration has no digit at the start,
 * it sorts based on string length
 *
 * @param { string } x
 * @param { string } y
 * @returns
 */
const sort_options = (x, y) => {
  if (x.value.substring(0, 1) < y.value.substring(0, 1)) {
    return -1;
  }
  if (x.value.substring(0, 1) > y.value.substring(0, 1)) {
    return 1;
  }

  if (x.value < y.value) {
    return -1;
  }
  if (x.value > y.value) {
    return 1;
  }

  return 0;
};

/**
 * Sort products based on product_order, if present
 *
 * @param { object } products
 * @returns
 */
const sort_list = (x, y) => {
  if (!x?.product_order || !y?.product_order) {
    return 0;
  }

  if (x.product_order < y.product_order) {
    return -1;
  }
  if (x.product_order > y.product_order) {
    return 1;
  }

  return 0;
};

const set_selected_product = (product_id) => {
  store.dispatch("setProduct", {
    record_id: store.getters.record.id,
    product_id: product_id,
  });
};

const set_product = (product) => {
  return store.dispatch("setProduct", {
    record_id: store.getters.record.id,
    product_id: product.id,
    type: product.product.type,
    brand: product.product.brand,
    segment: product.product.segment,
    product: JSON.stringify(product.product),
    incentive: JSON.stringify(product.incentive),
  });
};
const delete_product = (product_id) => {
  store.dispatch("deleteProduct", {
    record_id: store.getters.record.id,
    product_id: product_id,
  });
};

export { map_products, set_product, delete_product, set_selected_product };
