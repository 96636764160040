const escapeHTML = (str) =>
  str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;",
      }[tag])
  );

const encodeUrl = (to) => {
  const url = process.env.VUE_APP_BASE_ENCODE_URL;
  let queryString = "";
  let i = 0;
  for (const [key, value] of Object.entries(to.query)) {
    i++;
    if (i === 1) {
      queryString += key + "=" + value;
      continue;
    }

    queryString += "&" + key + "=" + value;
  }
  const nextUrl =
    url +
    "?prev=" +
    encodeURIComponent(process.env.VUE_APP_ENCODE_URL + to.path) +
    "&get=" +
    encodeURIComponent(escapeHTML(queryString));

  return nextUrl;
};

export default encodeUrl;
