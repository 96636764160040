/* eslint-disable no-unused-vars */
import ApiService from "../../plugins/axios";
import Loading from 'quasar/src/plugins/Loading.js';import date from 'quasar/src/utils/date.js';;

const bac = {
  state: () => ({}),
  mutations: {},
  actions: {
    start_bac_flow: ({ dispatch, rootGetters }, state = "") => {
      dispatch("setManualEan", false, { root: true });
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "start_bac_flow",
        state: state ?? true,
        ...rootGetters.rabbitRoute,
        body: {
          postcode: rootGetters.record.Postcode,
          house_number: rootGetters.record.Huisnummer,
          house_number_extension: rootGetters.record.HuisnummerToev ?? "",
          street: rootGetters.record.Straat,
          city: rootGetters.record.Woonplaats,
          record_PID: rootGetters.record.id,
        },
      }).then((response) => {
        dispatch(
          "startPoll",
          {
            id: false,
            name: "start_bac_flow",
            restart: false,
          },
          { root: true }
        );
      });
    },
    get_installationdetails: ({ dispatch, rootGetters }) => {
      // ApiService.post("update/bac", {
      //   record_id: rootGetters.record.id,
      //   task: "get_installationdetails",
      //   state: true,
      //   body: {
      //     record_PID: rootGetters.record.id,
      //   },
      // }).then((response) => {
      //   dispatch(
      //     "startPoll",
      //     {
      //       id: false,
      //       name: "get_installationdetails",
      //       restart: false,
      //     },
      //     { root: true }
      //   );
      // });
    },
    save_additional_information: ({ rootGetters }, contact_date) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_additional_information",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          residential: rootGetters.projectSpecific.residential,
          contract_with_another_supplier:
            rootGetters.projectSpecific.contract_with_another_supplier,
          contact_date,
        },
      }).then(() => {});
    },
    save_ean_details: ({ rootGetters }) => {
      var eans = [];
      if (rootGetters.projectSpecific["EAN Elektra"]) {
        eans.push(rootGetters.projectSpecific["EAN Elektra"]);
      }
      if (rootGetters.projectSpecific["EAN Gas"]) {
        eans.push(rootGetters.projectSpecific["EAN Gas"]);
      }
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_ean_details",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          connect_ean: eans,
        },
      }).then(() => {});
    },
    get_expected_consumption: ({ dispatch, rootGetters }, values) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "get_expected_consumption",
        state: false,
        ...rootGetters.rabbitRoute,
        body: values,
      }).then(() => {
        dispatch(
          "startPoll",
          {
            id: false,
            name: "get_expected_consumption",
            restart: false,
          },
          { root: true }
        );
      });
    },
    save_consumption: ({ rootGetters }) => {
      let body = {
        record_PID: rootGetters.record.id,
        payload: [
          {
            energy_type: "electricity",
            standard_annual_usages: [
              {
                direction_tariff: "supply_low",
                reading: rootGetters.projectSpecific["verbruik electra 2"] || 0,
              },
              {
                direction_tariff: "supply_normal",
                reading: rootGetters.projectSpecific["verbruik electra"] || 0,
              },
              {
                direction_tariff: "return_supply_low",
                reading: 0,
              },
              {
                direction_tariff: "return_supply_normal",
                reading:
                  rootGetters.projectSpecific[
                    "verbruik electra teruglevering"
                  ] || 0,
              },
            ],
          },
        ],
      };
      if (rootGetters.projectSpecific["verbruik gas"]) {
        body.payload.push({
          energy_type: "gas",
          standard_annual_usages: [
            {
              direction_tariff: "supply_normal",
              reading: rootGetters.projectSpecific["verbruik gas"] || 0,
            },
          ],
        });
      }

      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_consumption",
        ...rootGetters.rabbitRoute,
        state: true,
        body,
      }).then(() => {});
    },
    save_start_date: ({ rootGetters }, { electricity, gas }) => {
      const start_date_elec = electricity ?? rootGetters.startDate.electricity;
      const start_date_gas = gas ?? rootGetters.startDate.gas;

      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_start_date",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          dates: [
            {
              energy_type: "electricity",
              start_date: date.formatDate(start_date_elec, "YYYY-MM-DD"),
            },
            {
              energy_type: "gas",
              start_date: date.formatDate(start_date_gas, "YYYY-MM-DD"),
            },
          ],
        },
      }).then(() => {});
    },
    save_start_date_and_select_proposition: (
      { rootGetters },
      { electricity, gas }
    ) => {
      const start_date_elec = electricity ?? rootGetters.startDate.electricity;
      const start_date_gas = gas ?? rootGetters.startDate.gas;
      const duration =
        rootGetters.projectSpecific["selectedProduct"].product.duur.split(" ");

      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_start_date_and_select_proposition",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          campaign_id:
            rootGetters.projectSpecific["selectedProduct"].product.campaignid,
          incentive_id:
            rootGetters.projectSpecific["selectedProduct"].incentive
              .IncentiveId == "Geen"
              ? rootGetters.projectSpecific["selectedProduct"].product
                  .incentiveidno
              : rootGetters.projectSpecific["selectedProduct"].incentive
                  .IncentiveId,
          duration_in_years: duration[0],
          dates: [
            {
              energy_type: "electricity",
              start_date: date.formatDate(start_date_elec, "YYYY-MM-DD"),
            },
            {
              energy_type: "gas",
              start_date: date.formatDate(start_date_gas, "YYYY-MM-DD"),
            },
          ],
        },
      }).then(() => {});
    },
    get_offers: ({ dispatch, rootGetters }, values) => {
      if (!Loading.isActive) {
        Loading.show();
      }
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "get_offers",
        state: false,
        ...rootGetters.rabbitRoute,
        body: values,
      }).then((response) => {
        dispatch(
          "startPoll",
          {
            id: false,
            name: "get_offers",
            restart: false,
          },
          { root: true }
        );
      });
    },
    select_proposition: ({ dispatch, rootGetters }, values) => {
      const duration =
        rootGetters.projectSpecific["selectedProduct"].product.duur.split(" ");
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "select_proposition",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          campaign_id:
            rootGetters.projectSpecific["selectedProduct"].product.campaignid,
          incentive_id:
            rootGetters.projectSpecific["selectedProduct"].incentive
              .IncentiveId == "Geen"
              ? rootGetters.projectSpecific["selectedProduct"].product
                  .incentiveidno
              : rootGetters.projectSpecific["selectedProduct"].incentive
                  .IncentiveId,
          duration_in_years: duration[0],
        },
      });
    },
    save_budget_bill_amount: ({ dispatch, rootGetters }, values) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_budget_bill_amount",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          budget_bill_amount:
            rootGetters.projectSpecific["voorschotbedrag"] ??
            rootGetters.projectSpecific["_ToAmMonth_"],
        },
      });
    },
    save_personal_details: ({ dispatch, rootGetters }, values) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_personal_details",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          initials: rootGetters.record.Initialen,
          last_name: rootGetters.record.Achternaam,
          gender: rootGetters.record.Geslacht == "M" ? "male" : "female",
          date_of_birth: rootGetters.Gebdat,
        },
      });
    },
    save_correspondence: ({ dispatch, rootGetters }, preferred_channel) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_correspondence",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          preferred_channel,
          email: rootGetters.record.Email,
          phone: rootGetters.record.Telefoon,
        },
      });
    },
    save_payment_details: ({ dispatch, rootGetters }, values) => {
      ApiService.post("update/bac", {
        record_id: rootGetters.record.id,
        task: "save_payment_details",
        state: true,
        ...rootGetters.rabbitRoute,
        body: {
          record_PID: rootGetters.record.id,
          iban: rootGetters.record.IBAN,
          use_correspondence_address: true, //! if use_correspondence_address is false, then Invoice address needs to be provided.
          incoming_payment_method:
            rootGetters.record.betaalwijze == "handmatig"
              ? "manual"
              : "direct_debit",
        },
      });
    },
  },
  getters: {},
};

export default bac;
